import { spacing } from '@src/Styles'
import { SetStateAction, useEffect, useMemo } from 'react'
import OrderCard from '@src/Order/OrderCard'
import OrdersEmpty from '@src/Orders/OrdersEmpty'
import { useWonders } from '@src/Fetch/helpers'
import { PAYMENT_TYPES, getOrderRootWid } from '@src/Order/helpers'
import { CMAOrderType } from '@src/types'
import { CsbOrderMetadata } from '@kjt01/greendot-wasm'
import OrderSupportCallCard, {
  getSupportCallCustomerPhone,
  isCsbCmaSupportCall,
} from '@src/Order/OrderSupportCallCard'
import { PaymentType } from '@src/Orders/OrdersPaymentType'
import { PaymentStatus } from '@src/Orders/OrdersPaymentStatus'
import { useFlags } from 'launchdarkly-react-client-sdk'

const OrdersContent = ({
  search,
  startDate,
  endDate,
  serviceType,
  setIsReady,
  paymentType,
  paymentStatus,
  setSummary,
}: {
  search: string
  startDate: string
  endDate: string
  serviceType: CMAOrderType | 'all'
  setIsReady: React.Dispatch<SetStateAction<boolean>>
  paymentType: PaymentType
  paymentStatus: PaymentStatus
  setSummary: React.Dispatch<SetStateAction<CsbOrderMetadata>>
}) => {
  const { cmaDriverOrdersAndPayment } = useFlags()

  const { data, isLoading } = useWonders([
    `get_location_orders`,
    {
      start_time: startDate,
      end_time: endDate,
      service_type: serviceType === 'all' ? null : serviceType,
      ...(paymentType != null
        ? {
            payment_types: PAYMENT_TYPES[paymentType],
          }
        : {}),
      ...(paymentStatus != null
        ? {
            client_reported_payment_filter:
              paymentStatus === 'paid'
                ? 'orders_with_client_reported_payment'
                : 'orders_without_client_reported_payment',
          }
        : {}),
    },
    {
      refreshInterval: 3000,
    },
  ])

  const { data: drivers = [] } = useWonders(
    cmaDriverOrdersAndPayment === true ? ['get_restaurant_drivers'] : null,
  )

  useEffect(() => {
    if (data != null) {
      setSummary(data.metadata)
    }
  }, [data, setSummary])

  const cmaOrders = useMemo(() => data?.order_summaries ?? [], [data])

  const orders =
    search === ''
      ? cmaOrders
      : cmaOrders.filter(({ order }) => {
          const contactPhoneNumber =
            order.customer_idn_complex.phone_complex?.contact_phone_number

          const phoneNumber =
            order.customer_idn_complex.phone_complex?.phone_number

          return (
            ('000' + order.ticket_number)
              .slice(-3)
              .includes(search.replace('#', '')) ||
            order.ticket_number?.toString().includes(search) === true ||
            (contactPhoneNumber != null
              ? contactPhoneNumber.includes(search.replaceAll('-', '')) === true
              : phoneNumber?.includes(search.replaceAll('-', ''))) === true
          )
        })

  const supports = data?.support_calls ?? []
  const supportCallsWithReceipts = supports.filter(
    (call) =>
      call.support_props.support_reason.kind !== 'client_call' &&
      call.support_props.support_reason.kind !== 'complaint',
  )

  const supportCalls =
    search === ''
      ? supportCallsWithReceipts
      : supportCallsWithReceipts.filter((call) => {
          const customerPhoneNumber = getSupportCallCustomerPhone(call)

          return (
            ('000' + (call.support_props.support_number ?? ''))
              .slice(-3)
              .includes(search.replace('#', '')) ||
            (customerPhoneNumber != null &&
              customerPhoneNumber.includes(search.replaceAll('-', '')) === true)
          )
        })

  const allOrders = [...orders, ...supportCalls].sort((a, b) => {
    let a_number
    let b_number

    if (isCsbCmaSupportCall(a)) {
      a_number = a.support_props.support_number ?? 0
    } else {
      a_number = a.order.ticket_number ?? 0
    }

    if (isCsbCmaSupportCall(b)) {
      b_number = b.support_props.support_number ?? 0
    } else {
      b_number = b.order.ticket_number ?? 0
    }

    return b_number - a_number
  })

  useEffect(() => {
    if (!isLoading) {
      setIsReady(true)
    }
  }, [setIsReady, isLoading])

  if (data == null) return null

  return (
    <div css={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {orders.length === 0 ? (
        <div
          css={{
            display: 'flex',
            flex: 1,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <OrdersEmpty />
        </div>
      ) : (
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
            gridAutoRows: 'max-content',
            gap: spacing.base,
            paddingBottom: spacing.base,
            width: '100%',
            flex: 1,
          }}
        >
          {allOrders.map((order) => {
            if (isCsbCmaSupportCall(order)) {
              return (
                <OrderSupportCallCard
                  key={order.support_props.support_number}
                  supportCall={order}
                />
              )
            } else {
              const { order: o, firstd_data } = order
              return (
                <OrderCard
                  key={getOrderRootWid(o)}
                  order={o}
                  deliveryStatus={firstd_data}
                  drivers={drivers ?? []}
                />
              )
            }
          })}
        </div>
      )}
    </div>
  )
}

export default OrdersContent

import { msg } from '@lingui/core/macro'
import { MessageDescriptor } from '@lingui/core'
import { useLingui } from '@lingui/react'
import { MenuItem, Select, useMediaQuery, useTheme } from '@mui/material'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'

type Option = {
  label: MessageDescriptor
  value: 'paid' | 'unpaid' | null
}

const options: Option[] = [
  { label: msg`All Paid and Unpaid`, value: null },
  { label: msg`Paid Only`, value: 'paid' },
  { label: msg`Unpaid Only`, value: 'unpaid' },
]

export type PaymentStatus = 'paid' | 'unpaid' | null

const OrdersPaymentStatus = ({
  paymentStatus,
  setPaymentStatus,
}: {
  paymentStatus: PaymentStatus
  setPaymentStatus: React.Dispatch<React.SetStateAction<PaymentStatus>>
}) => {
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  const { i18n } = useLingui()
  const { typography } = useTypographyContext()

  return isMobileView ? (
    <div css={{ width: '100%' }}>
      <Select
        displayEmpty
        value={paymentStatus ?? ''}
        onChange={(e) => {
          const value = e.target.value as PaymentStatus | ''
          setPaymentStatus(value === '' ? null : value)
        }}
        sx={{
          width: '100%',
          fieldset: {
            border: `${constants.borderWidths.regular}px solid ${colors.neutrals[50]}`,
          },
          '.MuiSelect-select': {
            paddingBlock: `${spacing.base}px`,
            textAlign: 'center',
          },
          borderRadius: `${constants.borderRadius.medium}px`,
          outline: 'none',
          caretColor: colors.neutrals[400],
          color: colors.neutrals[500],
          ...typography.title.mediumRegular,
          background: colors.white[100],
          ':disabled': {
            background: colors.neutrals[50],
            opacity: 0.5,
          },
          ':focus': {
            borderColor: colors.neutrals[400],
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.value ?? 'total'} value={option.value ?? ''}>
              {i18n._(option.label)}
            </MenuItem>
          )
        })}
      </Select>
    </div>
  ) : (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        padding: spacing.base,
        gap: spacing.normal,
        background: colors.primary2[50],
        color: colors.neutrals[500],
        border: `1px solid ${colors.primary2[100]}`,
        borderRadius: constants.borderRadius.medium,
      }}
    >
      {options.map((option) => {
        return (
          <div
            key={option.value ?? 'total'}
            css={{ display: 'flex', width: '100%' }}
          >
            <button
              css={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingBlock: spacing.small,
                background:
                  paymentStatus === option.value ? colors.white[500] : 'none',
                border: 'none',
                borderRadius: constants.borderRadius.medium,
                cursor: 'pointer',
                ':hover': {
                  background: colors.primary2[25],
                },
                ':active': {
                  color: colors.white[500],
                  background: colors.primary2[500],
                },
              }}
              onClick={() => setPaymentStatus(option.value)}
            >
              <div
                css={{
                  ...typography.label.large,
                  textTransform: 'uppercase',
                }}
              >
                {i18n._(option.label)}
              </div>
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default OrdersPaymentStatus

import { t } from '@lingui/core/macro'
import Orders from '@src/Orders'
import SuspenseBoundary from '@src/SuspenseBoundary'
import Head from 'next/head'

const OrdersPage = () => {
  return (
    <>
      <Head>
        <title>{t`Orders`}</title>
      </Head>

      <SuspenseBoundary>
        <Orders />
      </SuspenseBoundary>
    </>
  )
}

export default OrdersPage

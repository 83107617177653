import { Trans } from '@lingui/react/macro'
import { spacing } from '@src/Styles'
import EventBusyIcon from '@mui/icons-material/EventBusy'

const DateTimeInvalid = () => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: spacing.base,
      }}
    >
      <EventBusyIcon css={{ height: 48, width: 48 }} />
      <div>
        <Trans>Date is Invalid</Trans>
      </div>
    </div>
  )
}

export default DateTimeInvalid

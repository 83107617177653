import {
  CmaOrderSummary,
  CsbCmaSupportCall,
  PhoneNumberE164,
} from '@kjt01/greendot-wasm'
import { Trans } from '@lingui/react/macro'
import { useLingui } from '@lingui/react'
import { formatPhone } from '@src/Help/helpers'
import { SUPPORT_REASONS } from '@src/Order/helpers'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import Link from 'next/link'

const OrderSupportCallCard = ({
  supportCall,
}: {
  supportCall: CsbCmaSupportCall
}) => {
  const { i18n } = useLingui()

  const { typography } = useTypographyContext()

  const supportReasonLabel =
    SUPPORT_REASONS[supportCall.support_props.support_reason.kind]

  const customerPhoneNumber = getSupportCallCustomerPhone(supportCall)

  return (
    <Link
      href={`/orders/support?wid=${supportCall.support_call_wid}`}
      css={{ textDecoration: 'none', display: 'grid' }}
      shallow={true}
    >
      <div
        css={{
          width: '100%',
          minWidth: 320,
          border: `1px solid ${colors.neutrals[50]}`,
          borderRadius: constants.borderRadius.medium,
          background: colors.white[50],
          ':hover': {
            background: colors.primary2[50],
            borderColor: colors.primary2[500],
            '#pill': {
              background: colors.dish[50],
            },
          },
          color: colors.neutrals[500],
          ':active': {
            color: colors.white[100],
            background: colors.primary2[500],
            borderColor: colors.primary2[500],
          },
          cursor: 'pointer',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing.normal,
            padding: spacing.normal,
          }}
        >
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div css={{ ...typography.title.large }}>
              #C{supportCall.support_props.support_number}
            </div>
            {supportReasonLabel != null ? (
              <div css={{ ...typography.label.large }}>
                {i18n._(supportReasonLabel)}
              </div>
            ) : null}
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Trans>Other</Trans>
            </div>
            <div>{formatPhone(customerPhoneNumber)}</div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default OrderSupportCallCard

export const getSupportCallCustomerPhone = (supportCall: CsbCmaSupportCall) => {
  const supportReason = supportCall.support_props.support_reason
  const supportPayload = 'payload' in supportReason ? supportReason.payload : {}

  const customerPhoneNumber =
    'phone_number' in supportPayload
      ? (supportPayload.phone_number as PhoneNumberE164 | null)
      : 'order_phone_number' in supportPayload
        ? (supportPayload.order_phone_number as PhoneNumberE164)
        : null

  return customerPhoneNumber
}

export const isCsbCmaSupportCall = (
  order: CsbCmaSupportCall | CmaOrderSummary,
): order is CsbCmaSupportCall => {
  return 'support_call_wid' in order && 'support_props' in order
}
